import HomePage from './HomePage';
import AboutPage from './AboutPage';
import IssuesPage from './IssuesPage';
import ContactPage from './ContactPage';
import NavBar from './NavBar';
import TabBar from './TabBar';
import Footer from './Footer';
import { useMediaQuery } from 'react-responsive';
import ScrollToTop from './ScrollToTop';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ReactComponent as Logo } from '../images/KeithForAurora-v2-banner-logo.svg';

const pageTypes = {
    home: 'home',
    about: 'about',
    issues: 'issues',
    contact: 'contact'
};

const routes = {
    home: '/',
    about: '/about',
    issues: '/issues',
    contact: '/contact'
};

const getRouteForPage = (pageType) => {
    return routes[pageType] ?? '/error';
};

const BasePage = ({ pageType }) => {
    const isMobile = useMediaQuery({ maxWidth: '1024px' });

    const displayPage = () => {
        switch (pageType) {
            case pageTypes.home:
                //document.title = 'Keith for Aurora';
                return <HomePage />;
            case pageTypes.about:
                //document.title = 'About - Keith for Aurora';
                return <AboutPage />;
            case pageTypes.issues:
                //document.title = 'Issues - Keith for Aurora';
                return <IssuesPage />;
            case pageTypes.contact:
                //document.title = 'Contact - Keith for Aurora';
                return <ContactPage />;
            default:
                return <>Error: This page does not exist. How did you even get here???</>;
        }
    };

    const displayHomePageBanner = () => {
        if (pageType === pageTypes.home) {
            return <>
                <div className="home-page--banner-background">
                    <div className="home-page--banner-container">
                        <img
                            className="home-page--banner-image"
                            src={require('../images/keith-red-polo-torso-scaled.jpg')}
                            alt="Keith Larson holding a clipboard collecting petition signatures from Aurora voters."
                        />
                        <div className="home-page--banner-logo-container ">
                            <Logo
                                className="home-page--banner-logo"
                                //src={require('../images/KeithForAurora-logo.png')}
                                alt="Keith Larson Alderman At Large - Invest in community, not corporations"
                            />
                            <p className="home-page--banner-text">
                                Keith Larson is a new face to Aurora politics, but he is committed to being a voice in city council who will
                                advocate for the needs of the community and not rubber stamp more handouts to the rich while the rest of us
                                are left behind.
                            </p>
                        </div>
                    </div>
                </div>
            </>;
        }
    };

    return <>
        <ScrollToTop />
        <NavBar />
        <HelmetProvider>
            <Helmet>
                <meta property="og:image" content={'https://www.keithforaurora.com' + require('../images/KeithForAurora-v2-logo.png')} />
                <meta name="twitter:image" content={'https://www.keithforaurora.com' + require('../images/KeithForAurora-v2-logo.png')} />
            </Helmet>
            <div className="base-page--background">
                {displayHomePageBanner()}
                <div className="base-page-wrapper">
                    {displayPage()}
                </div>
            </div>
        </HelmetProvider>
        <Footer />
        {
            isMobile && <TabBar />
        }
    </>;
};

export {
    BasePage,
    pageTypes,
    getRouteForPage
};