import { Helmet } from 'react-helmet-async';

const IssuesPage = () => {
    return <>
        <Helmet>
            <title>Issues - Keith for Aurora</title>
            <meta property="og:url" content="https://keithforaurora.com/issues" />
            <meta property="og:title" content="Issues - Keith for Aurora" />
            <meta property="og:description" content="Learn about the issues that are important to Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@keithforaurora" />
            <meta name="twitter:title" content="Issues - Keith for Aurora" />
            <meta name="twitter:description" content="Learn about the issues that are important to Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="description" content="Learn about the issues that are important to Aurora Alderman-at-Large candidate Keith Larson" />
        </Helmet>
        <h1>The Top Issues</h1>
        <div className="page-content--container_max-width">
            <h2>❌ Pay-to-Play ❌</h2>
            <p>
                <span className="emphasis">Corruption</span> is a well known issue especially in D.C., Springfield, and Chicago, but it is also an issue in Aurora and many
                other municipalities.
                Working families make up most of the families in Aurora and most of us do not have money lying around to donate thousands
                of dollars to our elected representatives. But money talks and the voices of the rich are heard while the voices of
                ordinary working people are usually ignored.
            </p>
            <p>
                Huge subsidies are given to wealthy corporations and developers by the city, such as the Hollywood Casino which received
                millions of dollars in an over 2 decade long TIF district despite already making billions in revenue.
                Meanwhile, working taxpayers are expected to pick up the
                bill for development, like the Orchard Road sound wall that has not been fully funded and the rest of the bill will be picked
                up by taxpayers. Additionally, residents are consistently
                ignored when they express concerns over issues that the city has caused, such as the residents who have been suffering
                from the city's idea to use a special zoning permit to allow Hello Fresh to open a packaging facility right next
                to a residential neighborhood. This has caused issues including excessive noise, light during the night time, and significant
                air pollution for our neighbors.
            </p>
            <p>
                I will never take money from corporations and I will always advocate for my neighbors over corporations. The Hello Fresh
                situation was caused by the city and the city should be obligated to fix it for the residents rather than ignoring it to keep
                Hello Fresh happy while our neighbors pay the price. Problems like this can be prevented in the future by building a city
                council that cares about its constituents over the profits of a corporation.
            </p>
            <h2>Pedestrian and Road Safety</h2>
            <p>
                I have heard too many tragic stories of pedestrians, bikers, and drivers being hurt or killed on unsafe bike paths and roads.
                One example is Eola Road near Waubonsie Valley High School and Gregory R. Fischer Middle School. During the school year,
                children are often crossing the road to get home or go to McDonald's and Starbucks. The city has added a sign showing
                drivers their speed that tells them to slow down if they are driving too quickly, but there is still valid concern for the
                safety of children in our community. The city has said that children should just walk to the Eola & Ogden intersection to
                use the crosswalk, but I think a more accessible and safe solution, such as a footbridge, would be a safer solution that kids
                are more likely to use.
            </p>
            <p>
                And just south of there on Eola, there have been lots of car accidents at the curve in the road, and in some cases tragically
                resulting in death. This is not an easy problem to fix unfortunately due to the presence of houses preventing the road
                from easily being straightened out. But this is one unfortunate consequence of overdevelopment that happened without
                safety for our community in mind. I believe development does not have to come at the cost of our own safety and I think
                this is an extremely important thing for the city government to keep in mind as we continue to grow.
            </p>
            <h2>High Paying Jobs That Benefit All</h2>
            <p>
                Cost of living is increasing nationwide, and Aurora is no exception, while wages are not keeping up with the rising costs.
                The city of Aurora cannot make our bosses pay us more, but we can attract more high paying jobs to our community. Aurora
                is the second biggest ciy in Illinois and is still growing. Development is inevitable in any growing city and we need
                to wisely decide how to develop our future. Currently, development is done with the highest bidder in mind, but I think
                it should be done with the community in mind. John Laesch, who is running for mayor, has proposed an idea to work towards
                installing <a href="https://johnforaurora.com/issues" target="_blank">public high-speed broadband</a> in Aurora. I agree
                that this would be a great type of development for Aurora. It would bring in high paying jobs and the result would be
                high-speed internet that is more affordable than the current internet we have available. It would also attract businesses
                in the tech industry, as we have seen in other cities that have done similar projects, like Chattanooga, TN.
            </p>
            <p>
                I also believe there are more opportunities to employ skilled people in our community for great mutual benefit. Everyone
                in government talks about the mental health crisis, but few people offer actual solutions. 911 is a resource everyone knows
                about to get help for many issues from health issues, to safety, to wellness checks. But the need for help often outweighs
                the available resources. A few other cities, such as Colorado Springs, have diversified emergency resources to provide
                relief to first responders and greater service to the community. Some examples are non-emergency medical services who can
                help seniors living alone with needs that don't require an emergency room visit or ambulance that currently go to EMTs.
                Also, mental health support is made accessible for people who need mental health support or have a wellness check called in
                by a loved one. Currently, police are expected to be able to handle these situations as if they should be expected to be
                trained for every possible crisis. This would employ more people in our community, such as mental health professionals,
                and provide much needed service with greater accessibility.
            </p>
            <h2>Planning for the Future</h2>
            <p>
                Everyone is talking about AI and automation these days with both excitement and fear. We are already seeing some jobs being
                replaced by machines and we need to be proactive before more people become unemployed. I write code for a living and AI
                is a common topic in my field, and I think the advancement of AI is partly overstated, but even without artificial general
                intelligence, automation is still a growing factor in unemployment. Machines can make our jobs and lives easier, but
                the harm of automation comes from layoffs to increase profits when machines are able to replace labor, benefitting corporate
                shareholders and hurting working people. Bosses are firing their employees, not themselves, and this is why I believe
                <span className="emphasis"> employee owned businesses and cooperatives</span> are the safest bets we have to prevent mass unemployment due to increasing
                automation. Machines should make our lives better, not collapse our economy, and this is why I would like to see more
                employee owned business and cooperatives in Aurora, because they would all benefit from automation rather than most losing
                their income to automation.
            </p>
        </div>
    </>;
};

export default IssuesPage;