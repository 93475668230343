import { Link, useLocation } from 'react-router-dom';
import { pageTypes, getRouteForPage } from './BasePage';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as Logo } from '../images/KeithForAurora-v2-long-transparent.svg';

const NavBar = () => {
    const location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: '1024px' });

    const isRouteSelected = (pageType) => {
        return getRouteForPage(pageType) === location.pathname;
    };

    return <>
        <div className="nav-bar">
            {
                !isMobile &&
                <>
                    <Link className="nav-bar--logo" to={'/'}>
                        <Logo className="nav-bar--logo_img"
                            //src={require('../images/KeithForAurora-v2-long-transparent.svg') /*require('../images/KeithForAurora-logo-long-transparent.png')*/}
                            alt="Keith Larson for Aurora Alderman At Large"
                            aria-label="Logo and link to home page" />
                    </Link>
                    <span className="nav-bar--button-container">
                        <Link className={`nav-bar--route-link ${isRouteSelected(pageTypes.home) ? 'nav-bar--route-link_selected' : ''}`}
                            aria-label="Home page"
                            to={getRouteForPage(pageTypes.home)}>
                            Home
                        </Link>
                        <Link className={`nav-bar--route-link ${isRouteSelected(pageTypes.about) ? 'nav-bar--route-link_selected' : ''}`}
                            aria-label="About page"
                            to={getRouteForPage(pageTypes.about)}>
                            About
                        </Link>
                        <Link className={`nav-bar--route-link ${isRouteSelected(pageTypes.issues) ? 'nav-bar--route-link_selected' : ''}`}
                            aria-label="Issues page"
                            to={getRouteForPage(pageTypes.issues)}>
                            Issues
                        </Link>
                        <Link className={`nav-bar--route-link ${isRouteSelected(pageTypes.contact) ? 'nav-bar--route-link_selected' : ''}`}
                            aria-label="Contact page"
                            to={getRouteForPage(pageTypes.contact)}>
                            Contact
                        </Link>
                    </span>
                    <div className="nav-bar--social-container">
                        <Link className="nav-bar--social-button" to="https://www.facebook.com/profile.php?id=61567029285922" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://www.instagram.com/keithforaurora/" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-instagram"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://www.tiktok.com/@keithforaurora" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-tiktok"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://x.com/keithforaurora" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-x-twitter"></i>
                        </Link>
                    </div>
                    <div className="nav-bar--donation-container">
                        <Link 
                            className="nav-bar--donation-button"
                            aria-label="Donation button"
                            to="https://secure.actblue.com/donate/keithforaurora"
                            target="_blank" >
                            Donate
                        </Link>
                    </div>
                </>
            }
            {
                isMobile &&
                <>
                    <Link className="nav-bar--logo" to={'/'}>
                        <Logo className="nav-bar--logo_img"
                            //src={require('../images/KeithForAurora-logo-long-transparent.png')} 
                            alt="Keith Larson for Aurora Alderman At Large"
                            aria-label="Logo and link to home page" />
                    </Link>
                    <div className="nav-bar--social-container">
                        <Link className="nav-bar--social-button" to="https://www.facebook.com/profile.php?id=61567029285922" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://www.instagram.com/keithforaurora/" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-instagram"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://www.tiktok.com/@keithforaurora" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-tiktok"></i>
                        </Link>
                        <Link className="nav-bar--social-button" to="https://x.com/keithforaurora" target="_blank">
                            <i class="nav-bar--social-button_icon fa-brands fa-x-twitter"></i>
                        </Link>
                    </div>
                    <div className="nav-bar--donation-container">
                        <Link 
                            className="nav-bar--donation-button"
                            aria-label="Donation button"
                            to="https://secure.actblue.com/donate/keithforaurora"
                            target="_blank" >
                            Donate
                        </Link>
                    </div>
                </>
            }
        </div>
    </>;
};

export default NavBar;