import { Link, useLocation } from 'react-router-dom';
import { pageTypes, getRouteForPage } from './BasePage';

const TabBar = () => {
    const location = useLocation();

    const isRouteSelected = (pageType) => {
        return getRouteForPage(pageType) === location.pathname;
    };

    return <div className="tab-bar">
        <Link className={`tab-bar--item ${isRouteSelected(pageTypes.home) ? 'tab-bar--item_selected' : ''}`}
            aria-label="Home page"
            to={getRouteForPage(pageTypes.home)}>
            Home
        </Link>
        <Link className={`tab-bar--item ${isRouteSelected(pageTypes.about) ? 'tab-bar--item_selected' : ''}`}
            aria-label="About page"
            to={getRouteForPage(pageTypes.about)}>
            About
        </Link>
        <Link className={`tab-bar--item ${isRouteSelected(pageTypes.issues) ? 'tab-bar--item_selected' : ''}`}
            aria-label="Issues page"
            to={getRouteForPage(pageTypes.issues)}>
            Issues
        </Link>
        <Link className={`tab-bar--item ${isRouteSelected(pageTypes.contact) ? 'tab-bar--item_selected' : ''}`}
            aria-label="Contact page"
            to={getRouteForPage(pageTypes.contact)}>
            Contact
        </Link>
    </div>;
};

export default TabBar;