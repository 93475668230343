import { Helmet } from 'react-helmet-async';

const ContactPage = () => {
    return <>
        <Helmet>
            <title>Contact - Keith for Aurora</title>
            <meta property="og:url" content="https://keithforaurora.com/about" />
            <meta property="og:title" content="Contact - Keith for Aurora" />
            <meta property="og:description" content="View the contact info of Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@keithforaurora" />
            <meta name="twitter:title" content="Contact - Keith for Aurora" />
            <meta name="twitter:description" content="View the contact info of Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="description" content="View the contact info of Aurora Alderman-at-Large candidate Keith Larson" />
        </Helmet>
        <h1>How to Contact Me</h1>
        <div className="page-content--container_max-width">
            <div className="contact-container">
                <div className="contact-card">
                    <i class="contact-card--icon fa-regular fa-envelope"></i>
                    <span className="contact-card--label">Email</span>
                    <a className="contact-card--content" href="mailto:keithforaurora@gmail.com">keithforaurora@gmail.com</a>
                </div>
                <div className="contact-card">
                    <i class="contact-card--icon fa-solid fa-phone"></i>
                    <span className="contact-card--label">Phone</span>
                    <a className="contact-card--content" href="tel:+16304920276">(630) 492-0276</a>
                </div>
            </div>
            {/* <div className="send-message-container">
                <h1>Send Me a Message</h1>
            </div> */}
        </div>
    </>;
};

export default ContactPage;