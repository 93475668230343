import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';

const AboutPage = () => {
    const isMobile = useMediaQuery({ maxWidth: '1024px' });

    return <>
        <Helmet>
            <title>About - Keith for Aurora</title>
            <meta property="og:url" content="https://keithforaurora.com/about" />
            <meta property="og:title" content="About - Keith for Aurora" />
            <meta property="og:description" content="Learn about Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@keithforaurora" />
            <meta name="twitter:title" content="About - Keith for Aurora" />
            <meta name="twitter:description" content="Learn about Aurora Alderman-at-Large candidate Keith Larson" />
            <meta name="description" content="Learn about Aurora Alderman-at-Large candidate Keith Larson" />
        </Helmet>
        <h1>Meet Keith</h1>
        <div className="page-content--container_max-width">
            <div className="paragraph-container">
                <img
                    className="paragraph--image"
                    style={{ height: '16em' }}
                    src={require('../images/keith-sweater-polo.jpg')}
                    alt="A head shot of Keith"
                />
                <p>
                    My name is Keith Larson and I live about a mile southeast of downtown in the 3rd ward. I studied computer science
                    and physics at Elmhurst College and graduated in 2018. I have been working full time as a software engineer since
                    2018, shortly after I graduated college. At work, I develop and maintain features in a web application, and I used
                    what I have learned to build this website. I bought my house here on the east side in 2021 where my cat, Belle,
                    allows me to live. I have played guitar for most of my life and I enjoy playing in my free time to relax. I play
                    different types of guitar, but during the last year, I fell in love with classical guitar and have been enjoying
                    learning classical guitar as a new style of playing.
                </p>
            </div>
            <h2>Activism</h2>
            <div className="paragraph-container">
                {
                    isMobile &&
                    <img
                        className="paragraph--image"
                        style={{ height: '20em' }}
                        src={require('../images/keith-keffiyeh.jpg')}
                        alt="Keith wearing a Palestinian keffiyeh"
                    />
                }
                <div>
                    <p>
                        I have been increasingly involved in social justice activism, especially in the past year. I have been speaking out
                        in favor of a ceasefire in Gaza, which is a position many politicians are afraid of taking a stand on despite
                        approximately 70% of US citizens supporting a ceasefire and an end to the violence, including the majority of people
                        in each of the major political party affiliations. But I will not be a typical politician who is afraid to stand up
                        for what is right out of fear of backlash from an establishment that values money and power more than life.
                        My mom's side of the family is Jewish and I was raised going to a
                        synagogue in Naperville that we would drive out to. Some people falsely argue that it is antisemitic to
                        criticize Israel's actions, but this narrative is harmful in that it both justifies the genocide of the
                        Palestinian people and also minimizes the threat of actual antisemitism. Because of my Jewish background,
                        people are more likely to listen to me when I say the truth that Israel is an ethnostate and not a
                        representative of all Jewish people, as the mainstream narrative has adopted as an acceptable version of the
                        old antisemitic trope of Jewish people being part of some powerful monolith.
                    </p>
                    <p>
                        I believe all forms of bigotry are unacceptable and are everyone's problem, whether it is
                        anti-black racism, Islamophobia, antisemitism, or any other form of bigotry. This is a serious global issue, but it is
                        also very local. Just south of here in Plainfield, a 6 year old Palestinian boy, Wadee Alfayoumi, was murdered in a racist,
                        hate fueled attack. Aurora is a diverse city with people of many backgrounds, including thousands of Muslim people.
                        Our Muslim neighbors deserve to feel safe, and with the rise in anti-Muslim hate crimes, this is a genuine concern that
                        I have tried to raise at city council in public comment.
                    </p>
                </div>
                {
                    !isMobile &&
                    <img
                        className="paragraph--image"
                        style={{ height: '20em' }}
                        src={require('../images/keith-keffiyeh.jpg')}
                        alt="Keith wearing a Palestinian keffiyeh"
                    />
                }
            </div>
            <h2>Outdoor Activities</h2>
            <div className="paragraph-container">
                <img
                    className="paragraph--image"
                    style={{ height: '18em' }}
                    src={require('../images/keith-hiking.jpg')}
                    alt="Keith standing on the Virginia/West Virginia state line on the Appalachian Trail"
                />
                <p>
                    I enjoy going on walks and I have gone on a couple of backpacking trips in the past two years. I hiked
                    a 74 mile section of the Appalachian Trail in spring 2023 for a week and hiked in Marin County, California for a week
                    during the spring of 2024. Like many people, I sit at a desk most of the day staring at a screen for work. Technology
                    is a core component to our lives in the modern world, but taking a break every so often to just experience the beauty
                    of nature is something that refreshes me. Illinois may not have mountains, but there is natural beauty here in Aurora
                    that we can enjoy. I especially like taking walks along the Fox River Trail to experience a bit of nature and to get
                    my blood flowing. In our rapidly changing world and in a growing city like Aurora, it is important to maintain a
                    balance and preserve nature as much as we can. As humans, we shape the world around us more dramatically than any other
                    species, but it is important to remember that we are products of nature.
                    If we destroy nature, we destroy ourselves along with it.
                </p>
            </div>
        </div>
    </>;
};

export default AboutPage;